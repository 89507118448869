import React from "react";

import { Helmet } from "react-helmet";
import Banner from "../blocs/Banner";
import Header from "../components/headers/header_contact";
import Footer from "../components/footers/footer";
import FrmContact from "../components/forms/contact";

export default function Contact() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Contact - Fissuration.fr</title>
        <link
          rel="canonical"
          href="https://www.expertise-fissuration.fr/contact/"
        />
      </Helmet>
      <header>
        <Banner />
        <Header />
      </header>
      <main>
        <div className="relative bg-white">
          <div className="relative max-w-7xl mx-auto py-8 px-4">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                Demande de renseignements ou devis
              </span>
            </h1>
            <div className="hidden lg:block">
              <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
                Votre maison ou appartement est fissuré. Trouvez une solution
                avec l'équipe Fissuration. Causes des fissures, évaluation des
                risques, solutions travaux, aide sur le plan des recours....
              </p>
              <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
                Nous mettons tout en œuvre pour vous aider à résoudre votre
                problématique de fissures.
              </p>
            </div>
          </div>
        </div>

        <FrmContact />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}
